@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wdth,wght@75..100,300..800&family=Source+Code+Pro:wght@200..900&display=swap');

:root {
  font-size: 62.5%;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  font-size: 1.6rem;
  line-height: 1.5;
  font-family: 'Open Sans', sans-serif;
}

body,
input,
button,
textarea {
  color: #000000d9;
}

code {
  font-family: 'Source Code Pro', Menlo, Monaco, Consolas, 'Courier New', monospace;
}
